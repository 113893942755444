.betItem-enter {
  opacity: 0;
  transform: translateX(100%);
}
.betItem-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.betItem-exit {
  opacity: 1;
  transform: translateX(0%);
}
.betItem-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}
.betItem-enter-active,
.betItem-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.niceWork-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.niceWork-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.niceWork-exit {
  opacity: 1;
  transform: translateX(0%);
}
.niceWork-exit-active {
  opacity: 0;
  transform: translateX(100%);
}
.niceWork-enter-active,
.niceWork-exit-active {
  transition: opacity 500ms, transform 500ms;
}
