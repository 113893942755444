@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  background: white;
  background-color: white;
}

body {
  margin: 0;
  height: 100%;
  background: white;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background: white;
  background-color: white;
  height: 100%;
}
